import err from './imgs/err.png'
import done from './imgs/done.png'
import Swal from 'sweetalert2'

const ShowTestResult = (obj) => {

  const {name, id, passed, state} = obj.obj

  const Success = () => {
    
    return <>
    <div className="success-checkmark">
  <div className="check-icon">
    <span className="icon-line line-tip"></span>
    <span className="icon-line line-long"></span>
    <div className="icon-circle"></div>
    <div className="icon-fix"></div>
  </div>
</div>
</>
  }

  const Failure = () => {

    return <></>
  }

    return <>
        <div className="testing resultMain">
                
        <div className="box">
          <h2>{name}</h2>
        <div className="result">
              {state == 1? <Success/> : <Failure/>}
              </div>
              
             <h3> {state == 1? 'Test Passed Successfully' : "Something Went Wrong"}</h3>
        </div>
              </div>
    </>
}

export default ShowTestResult