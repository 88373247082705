import React, { useEffect } from "react";
import logo from './imgs/LOGO.png'
import { useRef,useState } from "react";
import App from "./App";
import TestTool from "./App";
import axios from "axios";
import AccountCreatedPrompt from "./accountCreated";
import IconsSignIn from "./icons";

const Sign = () => {

    const nameRef = useRef('')
    const [returnComp, setReturnComp] = useState(0)
    const [name, setName] = useState("")
    const [update, setUpdate] = useState(0)
    const [created, setCreated] = useState(false)
    const [userAccount, setUserAccount] = useState()

    // Refs
    const firstName = useRef()
    const lastName = useRef()
    const userName = useRef()
    const pass = useRef()
    const passConfirm = useRef()

    const userLog = useRef()
    const passLog = useRef()

    const launchTest = () => {

        if(nameRef.current.value){
            setName(nameRef.current.value)
            setReturnComp(1)
        }
    }

    const Signup = () => {

        const changeMode = (to) => {
            setCurrentState(to)
            setUpdate((e) => e+1)
        }

        const startGuest = () => {
          
            setReturnComp(1)
            setUpdate((e) => e+1)
        }

        const updateField = () => {

        }

        const updateOnChange = (ref, x) =>{
            if (ref.current.value > x){
                ref.current.styles.border = '1px solid green'
        
            }
        }

        const createAccount = () => {

            // validate User Details

            if (firstName.current.value.length < 2){
                firstName.current.style.border = '2px solid red'
                firstName.current.style.animation = 'fields'

            }else if (lastName.current.value.length < 2){
                lastName.current.style.border = '2px solid red'
                lastName.current.style.animation = 'fields'

            }else if (userName.current.value.length < 3){
                userName.current.style.border = '2px solid red'
                userName.current.style.animation = 'fields'

            }else if (pass.current.value.length < 7){
                pass.current.style.border = '2px solid red'
                pass.current.style.animation = 'fields'

            }else if (pass.current.value.length != passConfirm.current.value.length){
                passConfirm.current.style.border = '2px solid red'
                passConfirm.current.style.animation = 'fields'
            }else{
                // check user name

                axios.post('https://tester.magicbit.cc/api/checkUser', {
                    user : userName.current.value,
                    fname : firstName.current.value, 
                    lname : lastName.current.value,
                    key : pass.current.value
                }).then((e) => {
                    if (e.data.msg == 'User Name Already Taken. Please Log-In Instead'){
            
                        userName.current.style.border = '2px solid red'
                        userName.current.style.animation = 'fields'
                    }
                    
                    if(e.data.created == true){
                        setCreated(true)
                        setUpdate((e) => e+1)
                    }
                })
            }

        }


        return <>
        <div className="createAcc">

            <div className="card">

            <div className="left">

                <div className="text">
                <h1>Magicbit</h1>
                <h2>Test Tool</h2>
                </div>

                <img src={logo} alt="" />

            </div>
            
                <div className="right">

                    
                    <h2 className="state">Create New Account</h2>

                    <div className="details signup">
                        <div className="in fname">
                            <h3 className="inputText">First Name</h3>
                            <input type="text" ref={firstName} onChange={() => updateOnChange(firstName, 3)}/>
                        </div>

                    <div className="in lname">
                        <h3 className="inputText">Last Name</h3>
                        <input type="text" ref={lastName}/>
                    </div>

                    <div className="in userName">
                            <h3 className="inputText">User Name</h3>
                            <input type="text" ref={userName}/>
                        </div>

                    <div className="in pwd">
                        <h3 className="inputText">Password</h3>
                        <input type="password" ref={pass}/>
                    </div>

                    <div className="in pwdc">
                        <h3 className="inputText">Confirm Password</h3>
                        <input type="password" ref={passConfirm}/>
                    </div>

                    <button className="create" onClick={() => createAccount()}>
                        Create Account
                    </button>

                </div>

                <h3 className="toLogText">Log in if you already have a account. or continue to use the tool as a guest</h3>

                <div className="otherButtons">
                    <button className="toLogIn b" onClick={() => {changeMode(SignIn)}}>Log In</button>
                    <button className="guest b" onClick={() => {startGuest()}}>Guest Mode</button>
                </div>



                    </div>
                </div>

                </div>
        </>

    }
    useEffect(() => {
  
    })


    const SignIn = () => {

        // Sign in using localstorage
        let user = localStorage.getItem('mgcbt_test_user')
        console.log(user)

        if (user){
            console.log(user)
            axios.post('https://tester.magicbit.cc/api/userLogin', {
                userName: user,
                key : null,
                type: 1
            }).then((e) => {
                
                if (e.data.account){
                    setUserAccount(e.data.account)
                    setReturnComp(1)
                    setUpdate((e) => e+1)
                }else{
            
                    userLog.current.style.border = '2px solid red'
                    passLog.current.style.border = '2px solid red'
                }

            })
        }

        
        const changeMode = (to) => {
            setCurrentState(to)
            setUpdate((e) => e+1)
        }

        const login = () => {
            

            // Validate Credentials
            if (userLog.current.value.length < 3){
                userLog.current.style.border = '2px solid red'
                setUpdate((e) => e+1)

            }else if(passLog.current.value.length < 7){
                passLog.current.style.border = '2px solid red'
                setUpdate((e) => e+1)
            }
            else{
                // Check Credentials

                axios.post('https://tester.magicbit.cc/api/userLogin', {
                    userName: userLog.current.value,
                    key : passLog.current.value,
                    type: 0
                }).then((e) => {
                    
                    if (e.data.account){
                        
                        if (!localStorage.getItem('mgcbt_test_user')){
                            localStorage.setItem("mgcbt_test_user", e.data.account.userName)
                        }

                        setUserAccount(e.data.account)
                        
                        setReturnComp(1)
                        setUpdate((e) => e+1)
                    }else{
              
                        userLog.current.style.border = '2px solid red'
                        passLog.current.style.border = '2px solid red'
                    }

                })

            }

        }

        const startGuest = () => {
   
            setReturnComp(1)
            setUpdate((e) => e+1)
        }

       return <div className="signin">


    <div className="card">

    <div className="left">

        <div className="text">
        <h1>Magicbit</h1>
        <h2>Test Tool</h2>
        </div>

        <img src={logo} alt="" />

    </div>
    
    <div className="right">

            
    <h2 className="state">Log In to Your Account</h2>

   <div className="signContainer">
    
   <div className="in userName">
            <h3 className="inputText">User Name</h3>
            <input type="text" ref={userLog}/>
        </div>

    <div className="in pwd">
        <h3 className="inputText">Password</h3>
        <input type="password" ref={passLog}/>
    </div>

    <button className="signButton" onClick={() => {login()}}>
        Log In
    </button>

    <h3 className="toLogText">
        Don't have an account ? create a new account or access the tool directly via guest mode.
   </h3>

   </div>

   <div className="otherButtons">
    <button className="toLogIn b" onClick={() => {changeMode(Signup)}}>Create Account</button>
    <button className="guest b" onClick={() => {startGuest()}}>Guest Mode</button>
    </div>

    </div>


</div>

        </div>

        
    }

    const [currentState, setCurrentState] = useState(SignIn)

    if (created){
        return <AccountCreatedPrompt />
    }

    if (returnComp == 0){
    return <>
        {currentState}
 
    </>
    }else{

        return <TestTool props={userAccount}/>
    }
}

export default Sign