const AccountCreatedPrompt = (props) => {

    console.log(props)

    return <div className="newAccountPrompt">
        
        <div className="newAccPrmContainer">
            <h1>Your account has been succesfully created. please login now.</h1>
            <button className="log" onClick={() => {
                location.reload()
            }}>
                Log-In
            </button>
        </div>

    </div>
}


export default AccountCreatedPrompt