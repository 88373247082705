import axios from 'axios'
import ex from 'firmata_web'

const FinalReport = (object) => {

    let res = object.props
    console.log(res)


    const reloadPage = () => {
        ex.init_device()
        location.reload()
    }

    return <div className="report">

    <h2>Tests Completed</h2>


        <div className="resultComps">
            {res.map((e) => {
                return <div className="rComp" id={e.name} style={e.state == 1 ? {background: 'rgb(223, 255, 228)'} : {background: "rgb(255, 217, 217)"} }>
                    <h3>{e.name}</h3>
                    <h3>{e.state == 1? "Passed" : "Failed"}</h3>
                </div>
            })}

        </div>

        <button className="retest" onClick={() => {reloadPage()}}>Retest New Device</button>

    </div>
}

export default FinalReport