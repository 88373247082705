import { useEffect, useState, useRef } from "react"
import connectImg from './imgs/image.png'
import err from './imgs/err.png'
import done from './imgs/done.png'
import ex from "firmata_web"
import ShowTestResult from "./panelStatus"
import Sign from "./sign"
import FinalReport from "./final"
import axios from "axios"


const Nav = () => {

  return <>
    <div className="nav">
        <h2 className="title">Magicbit Test Tool</h2>

    </div>
  </>
}

const Body = (props) => {

  // let test = new Test()
  const [user, setUser] = useState(props.props.props)


  // For god's sake dont change this order. consequences will immeusarable
  const [components, setComponents] = useState([
    {name: "Connectivity", id:0, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "WIFI", id:1, passed:0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "Display", id:2, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},   
    {name: "LED", id:3, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "Buzzer", id:4, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "Buttons", id:5, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "Motors", id:6, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "Potentiometer", id:7, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "Charging", id:8, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "LDR", id:9, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "Reset", id:10, passed: 0, bg: '#DEF2F1', testFunc: null, state: 0},
    {name: "Batch & Serial", id:11, passed: 0,bg: '#DEF2F1', testFunc: null, state: 0}

  ])

  const [activePanel, setActivePanel] = useState(0)
  const [update, setUpdate] = useState(10)
  const [currentTestPanel, setCurrentTestPanel] = useState()
  const [testingMode, setTestingMode] = useState("auto")
  const [currentTest, setCurrentTest] = useState()
  const [wifi, setWifi] = useState({ssid: 0, passwrod: 0})
  const [deviceData, setDeviceData] = useState([{chipId: '0', version: '0', user: 'GUEST'}])
  const [showPreviousPrompt, setShowPrevious] = useState({display: 'flex', showed: false})
  const [previousData, setPreviousData] = useState({data: null})
  const [ssid_cook, setSsid_c] = useState('')

  

  // Testing Stuff
  const [ldr_val, setLdrVal] = useState(0)
  const [pot_values, setPotValues] = useState([0,0])
  const [reachedFinal, setReachedFinal] = useState(0)

  // Refs
  const wifiRef = useRef()
  const panelContainer = useRef()
  const wifiSsid = useRef()
  const wifiPassword = useRef()
  const batch = useRef()
  const serial = useRef()

  const TestComponent = (object) => {
    
      let index = components.indexOf(object)
      let chipId = 0
      let version = 0

      // Generate Report
      const Report = () => {

        let allTested = 0

        components.forEach((e) => {
          if (e == 0){
            allTested = 0
          }
        })
          return <FinalReport props={components}/>
      }

      // testing mode
      const test_next = () => {

        // test next compopnent if mode is auto
        setUpdate((e) => e+1)

        if (testingMode == "auto"){
         
          if (object.state == 1 || object.state == -1){
            TestComponent(object)
            setUpdate((e) => e+1)
          }
          setTimeout(() => {
            let next = index + 1
            TestComponent(components[next])
            setUpdate((e) => e+1)
          }, 2219)
        
        }else{

          setTimeout(() => {
            let next = index 
            TestComponent(components[next])
            setUpdate((e) => e+1)
          }, 4017)
        }
       
      
      }

      // Skip Test
      const Skip = () =>{
        let next = index + 1
        TestComponent(components[next])
        setUpdate((e) => e+1)
      }


      // Test Connectivity
      const TestConnectivity = () => {
        
        let test = 0

        if (object.state == 0){

          ex.connect_serial().then((e) => {
            if (e == 1){
              setUpdate((e) => e+1)

              ex.init_device().then((e) => {
                
                ex.launch_test().then((e) => {
                  test = e
 
                  if (test == "#TEST_MODE$"){
                    components[index].bg = "rgb(180, 255, 180)"
                    components[index].passed = done
                    components[index].state = 1

                    setUpdate((e) => e+1)
                    test_next()
                  }
                })
      
              })
              
              ex.get_info().then((e) => {


                if (e.length > 100){
                  location.reload()
                }

                chipId = e.substr(e.indexOf("#")+1, (e.indexOf(',V')-2))
                version = e.substr((e.indexOf(",V")+2), e.length-1)
                version = version.replace(/\$/g, '$')
        

                deviceData[0].chipId = chipId
                deviceData[0].version = version

                // Check if Already tested before
              
                // Check whether if already tested
                axios.get(`https://tester.magicbit.cc/api/previousRes/${deviceData[0].chipId}`).then(e => {

                    let testedUser = e.data.row.UserName
                    let chipId_p = e.data.row.chipId
                   
                    previousData.data = e.data.row
                    
                    if (showPreviousPrompt.showed == false){
                      showPreviousPrompt.display = 'flex'
                      setUpdate((e) => e+1)
                    }else{
                    
                    }
       

                }).catch((err) => {
                  console.log(err)
                })
                setUpdate((e) => e+1)
              })

              setComponents(components)
              setUpdate((e) => e+1)
              setActivePanel(1)
             
            }else{
              components[index].bg = "rgb(255, 157, 157)"
              components[index].passed = err
              components[index].state = -1
              
            }

          })

        return <>
             <div className="testing led">
                <h2 className="title">Testing Connectivity</h2>
              
              <div className="prompt">
                <h2>Loading Test Mode...</h2>
                <h3 className="q">If the Device didn't enter the test mode within few seconds try restarting page and device. The Page Will Refresh Automatically If the Device Didn't Initialized Properly.</h3>
                
              </div>

              </div>
        </>
        }else{
        
          return <ShowTestResult obj= {object}/>
        }
      }

      // Test LED
      const TestLED = () => {
      
        if (object.passed == 0){
          ex.analog_write_fmt(16, 256, 1)
          ex.analog_write_fmt(17, 256, 1)
          ex.analog_write_fmt(27, 256, 1)
          ex.analog_write_fmt(18, 256, 1)     
        
        const checkLED = (state)=> {
          if (state == true){
            components[index].bg = "rgb(180, 255, 180)"
            components[index].passed = done
            components[index].state = 1
            setUpdate((e) => e+1)
            // TestComponent(components[index+1])
          }else{
            components[index].bg = "rgb(255, 157, 157)"
            components[index].passed = err
            components[index].state = -1
            setUpdate((e) => e+1)
          }
          ex.analog_write_fmt(16, 0, 1)
          ex.analog_write_fmt(17, 0, 1)
          ex.analog_write_fmt(27, 0, 1)
          ex.analog_write_fmt(18,0, 1)

          test_next()
          
        }
      return <>
              <div className="testing led">
                <h2 className="title">Testing LED</h2>
              
              <div className="prompt">
                <h3 className="q">Were All LEDs Turned On ? </h3>
                <div className="buttons">
                    <button className="yes" onClick={() => {checkLED(true)}}>Yes</button>
                    <button className="no"  onClick={() => {checkLED(false)}}>No</button>
                </div>
              </div>

              </div>
      </>
        }else{
          return <ShowTestResult obj= {object}/>
        }

      }

      // Test LDR
      const TestLDR = () => {


        if (object.state == 0){
          
          try{
            let values = []
            let time = 0
            let speed = 500
           
            const interval = setInterval(() => {

              object.state == 1 ? clearInterval(interval) : console.log()

              ex.analog_read_fmt(36,1).then((e) => {
                time += 1
                setLdrVal(e)
                
                if (values.indexOf(e) == -1){
                  values.push(e)
                }
                // Passs
                if (values.length > 3){
                  components[index].bg = "rgb(180, 255, 180)"
                  components[index].passed = done
                  components[index].state = 1
                  
                  clearInterval(interval)
                  setUpdate((e) => e+1)
                  
                  test_next()
                  
  
                }
                // Fail
                if (time > 10){
                  // If the test already passed it cannot be failed again
                  if (components[index].state != 1){
                    components[index].bg = "rgb(255, 157, 157)"
                    components[index].passed = err
                    components[index].state = -1
                    setUpdate((e) => e+1)
                    clearInterval(interval)
                    test_next()
                  }else{
                    clearInterval(interval)
                  }
                }
                setUpdate((e) => e+1)
              })
            }, speed)
  
            
          }catch(err){
              console.log(err)
              components[index].bg = "rgb(255, 157, 157)"
              components[index].passed = err
              components[index].state = -1
              setUpdate((e) => e+1)
          }
          return <>
          <div className="testing ldr">
          <h2 className="title">Testing LDR Sensor</h2>
              <div className="msg prompt">
                <h2 className="txt">Change Light Level Around LDR for Few Times</h2>
                <div className="load"></div>
              </div>

              </div>
        </>
        }
        else{
          return <ShowTestResult obj= {object}/>
        }
       

      }

      // Test Display
      const TestDisplay = () => {
        
        if (object.passed == 0){
          ex.display_text_fmt("HELLO WORLD", 4, 32, 32)

        const checkDisplay = (state)=> {
          
          if (state == true){
            
            components[index].bg = "rgb(180, 255, 180)"
            components[index].passed = done
            components[index].state = 1
  
          }else{
            components[index].bg = "rgb(255, 157, 157)"
            components[index].passed = err
            components[index].state = -1
          }
          setUpdate((e) => e+1)
          ex.display_clear_fmt()
          test_next()
        }
        return <>
        <div className="testing display">
                <h2 className="title">Testing Display</h2>
              
              <div className="prompt">
                <h3 className="q">Were "Hello World" Shown in Display ?</h3>
                <div className="buttons">
                    <button className="check" onClick={() => {checkDisplay(true)}}>Yes</button>
                    <button className="check fail"  onClick={() => {checkDisplay(false)}}>No</button>
                </div>
              </div>

              </div>
        </>
        }else{
          return <ShowTestResult obj= {object}/>
        }
      }

      // Test Buzzer
      const TestBuzzer = () =>{

        if (object.passed == 0){
          ex.play_tone_fmt(512, 512, 1)
        }

        const checkBuzzer = (state) => {
          
          if (state){
            components[index].bg = "rgb(180, 255, 180)"
            components[index].passed = done
            components[index].state = 1
            setUpdate((e) => e+1)
            test_next()
            // TestComponent(components[index+1])
          }else{
            components[index].bg = "rgb(255, 157, 157)"
            components[index].passed = err
            components[index].state = -1
            setUpdate((e) => e+1)
            test_next()
            // TestComponent(components[index+1])
          }
     
        } 
        
        if (object.passed == 0){

        return <>
          <div className="testing buzzer">
                <h2 className="title">Testing Buzzer</h2>
              
              <div className="prompt">
                <h3 className="q">Did your hear anything from buzzer?</h3>
                <div className="buttons">
                    <button className="check" onClick={() => {checkBuzzer(true)}}>Yes</button>
                    <button className="check fail"  onClick={() => {checkBuzzer(false)}}>No</button>
                </div>
              </div>

              </div>
        </>
        }else{
          return <ShowTestResult obj= {object}/>
        }


      }

      // Test Buttons\
      const TestButtons = () => {

        
        if (object.state == 0){
          
          let left = false
          let right = false
          let succeed = 0
  
          const buttonInterval = setInterval(() => {
            
            ex.analog_read_fmt(35).then((e) =>{
              e == 0 ? left = true : left=false
            })
  
            ex.analog_read_fmt(34).then((e) =>{
              e == 0 ? right = true : right=false
            })

            if (object.state == 1){
              clearInterval(buttonInterval)
            }
  
            if (left && right){
              succeed += 1
            }

            if (succeed > 2){
              components[index].bg = "rgb(180, 255, 180)"
              components[index].passed = done
              components[index].state = 1
              clearInterval(buttonInterval)
              setUpdate((e) => e+1)
              test_next()
              
            }
  
          }, 1000)

          const notFound = () => {
            components[index].bg = "rgb(255, 157, 157)"
            components[index].passed = err
            components[index].state = -1
            clearInterval(buttonInterval)
            setUpdate((e) => e+1)
            test_next()
          }

          return <>
          <div className="testing buttons">
                  <h2 className="title">Testing Buttons</h2>
                
                <div className="prompt">
                  <h3 className="q">Press And Hold Both Buttons at Same Time for 1-4 Times.</h3>
                  <div className="buttons">
                    <button className="pressed" onClick={() => {notFound()}}>Both Pressed</button>
                  </div>
                      
                </div>

                </div>
          </>
        }else{
          return <ShowTestResult obj= {object}/>
        }

      }

      // Test POT
      const TestPot = () => {

        if (object.passed == 0){
          let a = 0
          let b = 0
          let time = 0

          function readPot(){

            ex.analog_read_fmt(39,1).then((e) => {
              if (e > 20 && e < 1500){
                pot_values[0] = 1
                setPotValues(pot_values)
              }
              if (e > 3000 && e < 4093){
                pot_values[1] = 1
                setPotValues(pot_values)
              }

          }).catch((err) => {
            components[index].bg = "rgb(255, 157, 157)"
            components[index].passed = err
            components[index].state = -1
            setUpdate((e) => e+1)
          })
        }

        const potInterval = setInterval(() => {

          a = pot_values[0]
          b = pot_values[1]

          if (components[index].state == 1){
            clearInterval(potInterval)

          }

          if (a == 1 && b == 1){
            components[index].bg = "rgb(180, 255, 180)"
            components[index].passed = done
            components[index].state = 1
            setUpdate((e) => e+1)
            clearInterval(potInterval)
            test_next()
            
          }
          readPot()
        }, 100)

        const potFailed = () => {
          components[index].bg = "rgb(255, 157, 157)"
          components[index].passed = err
          components[index].state = -1
          setUpdate((e) => e+1)
          test_next()
        }

        return <>
         <div className="testing pot">
                <h2 className="title">Testing Potentiometer</h2>
              
              <div className="prompt">
                <h3 className="q">Perform a Complete Rotate in Potentiometer Slowly</h3>
                <div className="buttons">
                  <button className="pot" onClick={() => {
               
                    components[index].bg = "rgb(255, 157, 157)"
                    components[index].passed = err
                    components[index].state = -1
                    clearInterval(potInterval)
                    setUpdate((e) => e+1)
                    test_next()
                  }}>Rotate Completed</button>
                </div>
                    
              </div>

              </div>
              </>
        }else{
          return <ShowTestResult obj= {object}/>
        }
      }

      // Test Motors
      const Testmotors = () => {

        if (object.passed == 0){
          ex.analog_write_fmt(16,300, 1)

        const checkMotor = (state) => {
          if (state){
            components[index].bg = "rgb(180, 255, 180)"
            components[index].passed = done
            components[index].state = 1
            setUpdate((e) => e+1)
            test_next()
            // TestComponent(components[index+1])
          }else{
            components[index].bg = "rgb(255, 157, 157)"
            components[index].passed = err
            components[index].state = -1
            setUpdate((e) => e+1)
            test_next()
            
          }
          ex.analog_write_fmt(16,0, 1)
        }


        return <>
          <div className="testing motors">
                <h2 className="title">Testing Motors</h2>
              
              <div className="prompt">
                <h3 className="q">Were Motors worked properly ?</h3>
                <div className="buttons">
                    <button className="check" onClick={() => {checkMotor(true)}}>Yes</button>
                    <button className="check fail"  onClick={() => {checkMotor(false)}}>No</button>
                </div>
              </div>

              </div>
        </>
        }else{
          return <ShowTestResult obj= {object}/>
        }

      }
      
      // Test Charging
      const TestCharging = () => {
        if (object.passed == 0){

          const checkCharging = (state) => {
            if (state == true){
              components[index].bg = "rgb(180, 255, 180)"
              components[index].passed = done
              components[index].state = 1
            }else{
              components[index].bg = "rgb(255, 157, 157)"
              components[index].passed = err
              components[index].state = -1
            }
            setUpdate((e) => e+1)
            test_next()

          }


        return <>
         <div className="testing charging">
                <h2 className="title">Testing Charging</h2>
              
              <div className="prompt">
                <h3 className="q">Connect a Battery and Check the Charging LED. Were the Charging LED Turned On ? </h3>
                <div className="buttons">
                    <button className="check" onClick={() => {checkCharging(true)}}>Yes</button>
                    <button className="check fail"  onClick={() => {checkCharging(false)}}>No</button>
                </div>
              </div>

              </div>
              </>
        }else{
          return <ShowTestResult obj= {object}/>
      }

      }

      // Test Wifi
      const TestWifi = () => {


        // Set Cookies to store wifi cred
        let ssid_c;
        let key_c;
        let cookiesFound = 0

        if (document.cookie.startsWith('mgbtwifi')){
          ssid_c = document.cookie.substring('mgbtwifi='.length, document.cookie.indexOf("|"))
          key_c = document.cookie.substring(document.cookie.indexOf("|")+1, document.cookie.length)
          
          wifi.ssid = ssid_c
          wifi.passwrod = key_c

          setUpdate((e) => e+1)
          cookiesFound += 1
        } 
        if (object.state == 0){
         
          const setWifiConfig = () => {

            // Setup Connection
            const pass = wifiPassword.current.value 
            const id = wifiSsid.current.value

            if (cookiesFound != 1 || id != ssid_c || pass != key_c){
              document.cookie = 'mgbtwifi='+id + '|'+ pass
             
              console.log("cookies set")
            }

            if (id != 0 && pass != 0){
              wifi.ssid = id
              wifi.passwrod = pass
              setUpdate((e) => e+1)

                console.log("Connecting...")
                console.log(wifi.ssid)
                console.log(wifi.passwrod)

                ex.wifi_read_fmt().then((e) => {
                  console.log(e)
                  if (e == '@L,10000$'){
                    console.log("connected")
                    components[index].bg = "rgb(180, 255, 180)"
                    components[index].passed = done
                    components[index].state = 1

                    setUpdate((e) => e+1)
                    test_next()
                  } 
                })

                ex.Wifi_config_fmt(wifi.ssid, wifi.passwrod).then((e) => {
                  
                  console.log(e)

                  if (e == '@L,10000$'){
                    console.log("connected")
                    components[index].bg = "rgb(180, 255, 180)"
                    components[index].passed = done
                    components[index].state = 1
                    setUpdate((e) => e+1)
                    test_next()
                  }else{
                    // components[index].passed = err
                    // components[index].state = -1
                    setUpdate((e) => e+1)

                  }

                })

                ex.wifi_read_fmt().then((e) => {
                  console.log(e)
                  if (e == '@L,10000$' || e == 'L,10000$'){
                    console.log("connected")
                    components[index].bg = "rgb(180, 255, 180)"
                    components[index].passed = done
                    components[index].state = 1

                    setUpdate((e) => e+1)
                    test_next()
                  } 
                })
        
            }else{
           
              setUpdate((e) => e+1)
            }
    
          }
    
    
          return <>
          <h1 className="title">Testing WIFI Connectivity</h1>
          <div className="testing wifi" ref={wifiRef}>
  
            <h2 className="title">Connect To a WI-FI Network</h2>
            
            <div className="windowContainer">
    
            <div className="ssid window">
              <h3>WIFI SSID</h3>
              <input type="text" placeholder="Network SSID" ref={wifiSsid}/>
            </div>
    
            <div className="password window">
              <h3>Password</h3>
              <input type="password" placeholder="Password" ref={wifiPassword}/>
            </div>
    
            <div className="buttons">
            <button onClick={() => {setWifiConfig()}}>Connect</button>
            <button className="skip" onClick={() => {Skip()}}>Skip Test</button>
            </div>
    
            </div>
            
          </div>
          </>
        }else{
          return <ShowTestResult obj= {object}/>
      }
      }
      // Test Reset
      const TestReset = () => {

        ex.get_info().then((e) => {

          chipId = e.substr(e.indexOf(":")+3, (e.indexOf('V1')-2) - e.indexOf(':') - 1)
          version = e.substr((e.indexOf(",V1")+2), (e.indexOf('$')-3))

          // setDeviceData([{version: version, chipId:chipId}])
          setUpdate((e) => e+1)
        })

        if (object.state == 0){

          const checkReset = (state) => {
            if (state == true){
              setUpdate((e) => e+1)
              components[index].bg = "rgb(180, 255, 180)"
              components[index].passed = done
              components[index].state = 1

              setUpdate((e) => e+1)
        
            }else{
              components[index].bg = "rgb(255, 157, 157)"
              components[index].passed = err
              components[index].state = -1

            }
            // setReachedFinal(1)
            test_next()
            setUpdate((e) => e+1)
            return <ShowTestResult obj= {object}/>
            
          }

          return <>
           <div className="testing reset">
           <h2 className="title">Testing Reset</h2>
              
              <div className="prompt">
                <h3 className="q"> Were the Device Resetted Successfully </h3>
                <div className="buttons">
                    <button className="check" onClick={() => {checkReset(true)}}>Yes</button>
                    <button className="check fail"  onClick={() => {checkReset(false)}}>No</button>
                </div>
              </div>
  
                </div>
                </>
        }else{
            setUpdate((e) => e+1)
            return <ShowTestResult obj= {object}/>
        }
        
      }

      //Input Batch No and Serial Number
      const BatchNum = () => {

        const saveTestData = () => {
          
          let bid = batch.current.value
          let sid = serial.current.value 

          if (bid && sid){

            components[index].bg = "rgb(180, 255, 180)"
            components[index].passed = done
            components[index].state = 1

            setUpdate((e) => e+1)

            // save to the database
            try{
              setDeviceData({chipId: chipId, version: version, user: user.UserName})
            }catch{
              setDeviceData({chipId: chipId, version: version, user: "GUEST"})
            }
            
            // 1. send data to backend
            const send = [deviceData, components, user, bid, sid]

              fetch("https://tester.magicbit.cc/api/saveTest", {
                headers: {
                  'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({data:send}),
              }).then((e) => {
             
              }).catch((e) => {
                console.log(e)
              })

              setReachedFinal(1)
                    
            setUpdate((e) => e+1)
      
          }

        }

        if (object.state == 0){

          return <>
          <h1 className="title">Batch and Serial Numbers</h1>
           <div className="testing bnsr">
            
            <div className="windowContainer">

            <h2 className="titleIn">Enter Following Details</h2>
    
            <div className="batch inpField">
              <h4>Batch Number</h4>
              <input type="text" placeholder="Batch Number" ref={batch}/>
            </div>
    
            <div className="serial inpField">
              <h4>Serial Number</h4>
              <input type="text" placeholder="Serial Number" ref={serial} />
            </div>
    
            <div className="buttons">
            <button onClick={() => {saveTestData()}}>Finish Test</button>
        
            </div>
    
            </div>
            
          </div> 
          </>
        }else{
          setUpdate((e) => e+1)
          return <ShowTestResult obj= {object}/>
        }

      }

      setUpdate((e) => e+1)
      
      switch (index){

        case 0:
          setCurrentTestPanel(TestConnectivity);
          break;
        case 2:
          setCurrentTestPanel(TestDisplay)
          break;
        case 9:
          setCurrentTestPanel(TestLDR)
          break;
        case 3:
          setCurrentTestPanel(TestLED)
          break
        case 4:
          setCurrentTestPanel(TestBuzzer)
          break
        case 5:
          setCurrentTestPanel(TestButtons)
          break
        case 6:
          setCurrentTestPanel(Testmotors)
          break
        case 7:
          setCurrentTestPanel(TestPot)
          break
        case 8:
          setCurrentTestPanel(TestCharging)
          break
        case 1:
          setCurrentTestPanel(TestWifi)
          break
        case 10:
          setCurrentTestPanel(TestReset)
          break
        case 11:
          setCurrentTestPanel(BatchNum)
          break

      }

  }

  const DeviceInfo = () => {

    return <>
      <div className="info">
        <h4 className="ver"> ChipId - {deviceData[0].chipId}</h4>
        <h4 className="id"> Version - {deviceData[0].version}</h4>
      </div>
    </>
  }

  const List = () => {

    return <div className="list">

        {components.map((e) => {
          
          return <div className="comp" style={{backgroundColor: e.bg}} onClick={() => {
            // TestComponent(e)
            // setTestingMode('manual')
            // e.name != 'Connectivity' ? setTestingMode('manual') : setTestingMode('auto') 
            }} key={e.id}>
          <h3>{e.name}</h3>
          <img src={e.passed} alt="" />

        </div>

        })}

      </div>
  }

  const Main = () => {

    // Before test 
    const Before = () => {

        return <>
          <div className="panel before">
            <h2 className="title">Connect Magicbit and Start Testing</h2>
            <img src={connectImg} alt="" />
            <button className="start" onClick={() => {TestComponent(components[0])}}>Start Testing</button>
          </div>
        </>
    }
    // During Test
    const During = () => {

      return <>
      <div className="panel during">
          {currentTestPanel}
      </div>
      </>
    }

    const ShowPreviousResults = (props) => {

    
 
      try{

        let data = previousData.data

        const showPr = useRef()

        const hidePrompt = () => {
            showPreviousPrompt.showed = true
            showPr.current.style.display = 'none'
            setUpdate((e) => e+1)
        }
        
        return <div className="showPrevious" ref={showPr}>


        <div className="data">
            <h2 className="q">This Device Has been Already Tested by {data.UserName}. Do You Want to Validate the Test?</h2>

            <div className="results">

                <div className="user cm">
                    <h3>Tested By {data.UserName}</h3>
            
                </div>
                
                <div className="date cm">
                    <h3>{data.date}</h3>
                </div>

                <div className="chip cm">
                    <h3>Chip ID</h3>
                    <h3>{data.chipId}</h3>
                </div>

                <div className="ver cm">
                    <h3>Version</h3>
                    <h3>{data.versionId}</h3>
                </div>

                <div className="con cm" style={{background: data.t_connectivity == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_connectivity_desc}</h3>
                </div>

                <div className="display cm" style={{background: data.t_display == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_display_desc}</h3>
                </div>

                <div className="cm" style={{background: data.t_ldr == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_ldr_desc}</h3>
                </div>

                <div className="cm" style={{background: data.t_led == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_led_desc}</h3>
                </div>

                <div className="cm" style={{background: data.t_display == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_buzzer_desc}</h3>
                </div>
                
                <div className="cm" style={{background: data.t_buttons == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_buttons_desc}</h3>
                </div>

                <div className="cm" style={{background: data.t_motors == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_motors_desc}</h3>
                </div>

                <div className="cm" style={{background: data.t_pot == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_pot_desc}</h3>
                </div>

                <div className="cm" style={{background: data.t_charging == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_charging_desc}</h3>
                </div>

                <div className="cm" style={{background: data.t_wifi == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_wifi_desc}</h3>
                </div>

                <div className="cm" style={{background: data.t_reset == 1 ? 'rgb(155, 255, 155)':'rgb(253, 154, 154)'}}>
                    <h3>{data.t_reset_desc}</h3>
                </div>
                
               

            </div>

              <div className="buttons">
                  <button className="validatesn" onClick={() => {hidePrompt()}}>Validate Test</button>
              </div>

              <div className="bns">

                    <h4>Batch - {data.batch_no}</h4>
                    <h4>Serial - {data.serial_no}</h4>

              </div>
          
          </div>
      </div>

      }catch(err){

        console.log("error at showPrevious")
      }


}
    const panels = [Before, During, ShowTestResult]
    let Active = panels[activePanel]

    return <>

    <div className="previousPrompt" style={{display: showPreviousPrompt.display}}>
      <ShowPreviousResults props={previousData}/>
    </div>

      <div className="panelContainer" ref={panelContainer}>
        <Active/>

      </div>

    </>

  }

  useEffect(() => {


    if (wifiSsid.current){
      wifiSsid.current.value = wifi.ssid
      wifiPassword.current.value = wifi.passwrod
    }


    if (showPreviousPrompt.showed == true){
      showPreviousPrompt.display = 'none'
    }

  })
 
  if (reachedFinal == 0){
  return <>
  
    <div className="container">
      <List/>
      <Main/>
      <DeviceInfo/>
    </div>
  </>
  }else{
    return <FinalReport props={components}/>
  }
}

const TestTool = (userAccount) => {

  return <>
   
   <Nav/>

    <Body props={userAccount}/>


  </>
}


export default TestTool